<template>
  <div class="detail">
    <div class="detail-info">
      <div class="info-header">
        <div class="h-left">
          <div class="left-img-box">
            <img class="left-img" :src="dataForm.img" alt="" />
          </div>
          <div class="left-name">
            <div class="name-top">
              <div class="top-name">{{ dataForm.name }}</div>
              <div class="top-num">获赞：{{ dataForm.fabulous_count }}</div>
            </div>
            <div class="name-bottom">
              {{ dataForm.remark }}
            </div>
          </div>
        </div>
        <div class="h-right" @click="onFabulous">
          {{ dataForm.fabulous_status == 1 ? "已点赞" : "点赞" }}
        </div>
      </div>
      <div class="info-introduction">
        <div class="title">教师个人简介</div>
        <div class="introduction-content">
          <div class="ic-left">
            <div class="ic-info-box">姓名：{{ dataForm.name }}</div>
            <div class="ic-info-box">性别：{{ dataForm.sex }}</div>
            <div class="ic-info-box">出生年月：{{ dataForm.birthday }}</div>
            <div class="ic-info-box">学历：{{ dataForm.education }}</div>
          </div>
          <div class="ic-right">
            <div class="ic-info-box">任职学校：{{ dataForm.school }}</div>
            <div class="ic-info-box">任教学科：{{ dataForm.subject }}</div>
            <div class="ic-info-box">任教年级：{{ dataForm.teach_grade }}</div>
          </div>
        </div>
      </div>
      <div class="info-certificate">
        <div class="title">个人资质证书</div>
        <div class="certificate-content">
          <img class="btn-icon" src="@/assets/certificate/ic_2.png" alt="" />
          <div class="swiper-box">
            <el-carousel
              height="190px"
              indicator-position="none"
              :autoplay="false"
              arrow="never"
            >
              <el-carousel-item
                v-for="(item, idx) in dataForm.cert_list"
                :key="idx"
              >
                <div class="certificate-list">
                  <div
                    class="certificate-item"
                    v-for="(item1, index) in item"
                    :key="index"
                  >
                    <div class="ci-left">
                      <span class="cil-name">{{ item1.name }}</span>
                      <span>{{ item1.date_creation }}更新</span>
                    </div>
                    <div class="ci-right" @click="onSee(item1.fk_cert_id)">
                      查看
                    </div>
                  </div>
                  <div
                    class="certificate-item"
                    style="background-color: transparent; box-shadow: none"
                    v-for="item2 in item.length > 0 ? 3 - (item.length % 3) : 0"
                    :key="item2.fk_cert_id"
                  ></div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <img class="btn-icon" src="@/assets/certificate/ic_1.png" alt="" />
        </div>
      </div>
      <div class="info-introduce">
        <div class="title">个人资质证书</div>
        <div class="introduce-box" v-html="dataForm.content"></div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="800px">
      <div class="tc-box" v-html="CertInfo.content"></div>
    </el-dialog>
  </div>
</template>

<script>
import APi from "@/api";
export default {
  data() {
    return {
      dialogVisible: false,
      searchForm: {
        fk_teacher_id: "",
      },
      dataForm: {},
      CertInfo: {},
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.searchForm.fk_teacher_id = this.$route.query.id;
    }
    this.initPage();
  },
  methods: {
    initPage() {
      APi.TeacherDetail({
        ...this.searchForm,
        fk_user_id: this.userInfo.fk_user_id || 0,
      }).then((res) => {
        if (res.status == 1) {
          this.dataForm = res.data;
          let list = [];
          for (let i = 0; i < this.dataForm.cert_list.length; i += 3) {
            list.push(this.dataForm.cert_list.splice(i, i + 3));
          }
          this.dataForm.cert_list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onFabulous() {
      if (!this.userInfo.fk_user_id) {
        this.checkLogin(
          "login",
          {
            url: "study_detail",
            id: this.$route.query.id,
          },
          "当前未登录，是否跳转进行登录再操作"
        );
        return;
      }
      if (status == 1) return;
      APi.Fabulous({
        model: 1,
        type: 3,
        fk_user_id: this.userInfo.fk_user_id,
        fk_info_id: this.dataForm.fk_teacher_id,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.initPage();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onSee(id) {
      APi.CertDetail({
        fk_cert_id: id,
      }).then((res) => {
        this.CertInfo = res.data;
        this.dialogVisible = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  padding: 150px 0 85px;
}
.detail-info {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  padding: 50px;
  box-sizing: border-box;
  .title {
    font-size: 24px;
    font-weight: 400;
    color: #000000;
    position: relative;
    &::after {
      content: "";
      width: 4px;
      height: 23px;
      border-radius: 2px;
      background-color: #0086ff;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .info-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    .h-left {
      display: flex;
      .left-img-box {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        .left-img {
          width: 100%;
          height: 100%;
        }
      }
      .left-name {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 17px 0;
        .name-top {
          display: flex;
          align-items: center;
          .top-name {
            font-size: 34px;
            font-weight: 400;
            color: #000000;
            padding-right: 24px;
          }
          .top-num {
            font-size: 18px;
            font-weight: 400;
            color: #999999;
          }
        }
        .name-bottom {
          font-size: 22px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .h-right {
      width: 130px;
      height: 60px;
      border: 2px solid #0086ff;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 400;
      color: #0086ff;
      cursor: pointer;
    }
  }
  .info-introduction {
    width: 100%;
    .introduction-content {
      padding-top: 40px;
      flex: 1;
      display: flex;
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      .ic-left,
      .ic-right {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .ic-info-box {
        padding-bottom: 30px;
      }
    }
  }
  .info-certificate {
    padding: 40px 0 70px;
    width: 100%;
    height: 170px;
    .certificate-content {
      width: 100%;
      padding-top: 52px;
      display: flex;
      align-items: center;
      .btn-icon {
        width: 18px;
        height: 31px;
      }
      .swiper-box {
        flex: 1;
        height: 100%;
        .certificate-list {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
        }
        .certificate-item {
          width: calc(33.33% - 16px);
          height: 170px;
          padding: 50px 22px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 0.5);
          border-radius: 10px;
          .ci-left {
            display: flex;
            flex-direction: column;
            font-size: 18px;
            font-weight: 400;
            color: #999999;
            .cil-name {
              font-size: 24px;
              font-weight: 400;
              color: #000000;
              padding-bottom: 18px;
            }
          }
          .ci-right {
            width: 80px;
            height: 40px;
            background: #0086ff;
            border-radius: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
  .info-introduce {
    width: 100%;
    padding-top: 70px;
    .introduce-box {
      padding-top: 38px;
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      line-height: 40px;
    }
  }
}
.tc-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
  overflow-y: auto;
  .img {
    width: 600px;
    height: 600px;
  }
}
</style>
